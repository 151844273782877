@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: 'Lato', sans-serif;
    @apply bg-app-grey-10
  }
}
@layer utilities {
    /* Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
}
@layer components {
  .form-control {
    @apply w-full rounded p-3 placeholder:text-placeholder
  }
  .form-group {
    @apply mb-7
  }
  .form-group > label {
    @apply mb-2 block cursor-pointer
  }
  .btn {
    box-shadow: inset 2px 2px 4px rgba(255, 255, 255, 0.16), inset -1px -1px 4px rgba(0, 0, 0, 0.08);
    @apply py-2 px-5 cursor-pointer text-white
  }
  .round-btn {
    @apply btn rounded-lg
  }
  .disabled {
    @apply !cursor-not-allowed
  }
  .btn-full {
    @apply w-full py-3 text-white rounded mt-2
  }
  .lined-text {
    @apply overflow-hidden text-center before:right-2 before:ml-[-50%] after:left-2 after:mr-[-50%]
  }

  .lined-text:before,
  .lined-text:after {
    @apply bg-app-grey-40 inline-block h-[1px] relative align-middle w-1/2 content-['']
  }
  .x-y-center {
    @apply flex items-center justify-center
  }
  .account-type__card{
    @apply p-6 bg-white rounded-lg shadow-sm flex mb-5 cursor-pointer hover:shadow-lg ease-in-out duration-500
  }
  .account-type__icon {
    @apply border border-app-orange-90 border-opacity-20 rounded p-2
  }
  .signup-nav__item {
    @apply mr-5 flex gap-5 mb-5 text-app-black-50
  }
  .signup-nav__item > .number{
    @apply border-[1.5px] rounded-full w-7 h-7 x-y-center text-sm
  }
  .form-error{
    @apply text-sm text-red-500 absolute
  }
  .custom-select > .custom-select__button{
    @apply form-control flex bg-white text-placeholder cursor-pointer
  }
  .custom-select__search{
    @apply w-full no-outline
  }
  .no-outline{
    @apply !outline-none
  }
  .custom-select__bubble{
    @apply bg-white w-1/2 mt-3 z-50 shadow-2xl rounded max-h-36 overflow-scroll
  }
  .custom-select__options{
    @apply max-h-48 overflow-scroll
  }
  .custom-select__item{
    @apply block text-center py-2 hover:bg-app-grey-40 font-semibold
  }
  /* Nav related*/
  .nav-icons{
	@apply my-0 mx-auto
  }
  .nav-item{
	  @apply text-sm text-app-black-50
  }
  .active{
	  @apply text-white border-b-4 border-[#f99245] text-sm
  }
  .rounded-avatar{
    @apply rounded-lg w-12 h-12 bg-center bg-no-repeat bg-cover
  }
  .rounded-avatar-lg{
    @apply rounded-lg w-16 h-16 bg-center bg-no-repeat bg-cover
  }
  .select-can-answer{
    @apply flex items-center cursor-pointer hover:bg-app-grey-10 py-5 -mx-4 px-4 border-b border-app-grey-20
  }
  .specific-people-list {
    @apply flex items-center py-2 cursor-pointer px-4 hover:bg-app-grey-20
  }
  .specific-people-list__check{
    @apply appearance-none h-4 w-4 border rounded-sm bg-white checked:bg-app-black-100 border-app-black-100 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer
  }
  .shadowed{
			box-shadow: 0px 4px 28px 0px #00000014;
  }
  .option-tray__item{
    @apply flex py-2 px-3 hover:bg-app-grey-20 items-center cursor-pointer
  }
  .option-tray__item > img {
    @apply mr-2
  }
  .benefit-card{
    box-shadow: 0px 8px 28px rgba(18, 16, 16, 0.03);
    @apply bg-white text-[20px] rounded cursor-pointer relative
  }
  .join-section{
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
  }
  .orange-overlay{
    background-color: rgba(240, 121, 0, 0.16);
  }
  .dark-orange-overlay{
    background-color: rgba(240, 121, 0, 0.25);
  }
  .benefit-card__image{
    @apply rounded bg-no-repeat bg-center bg-cover bg-blend-soft-light orange-overlay group-hover:dark-orange-overlay transition
  }
  .benefit-card__subtext{
    @apply sm:hidden group-hover:block my-8 text-[16px] transition ease-in-out delay-150
  }
  .benefit-card__textarea{
    @apply p-5 w-full absolute bottom-0 bg-white overflow-hidden
  }
  :disabled{
    @apply opacity-50 cursor-not-allowed pointer-events-none
  }
  .loader {
  display: block;
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #F07900;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
  @apply  mx-3 my-0.5
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
}